<template>
  <div class="content">
    <Slider :slides="slides" v-slot="{ currentSlide }">
    <!-- <a :href="currentSlide.link" target="_blank"> -->
        <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
    <!-- </a> -->
    </Slider>

    <router-view></router-view>

    <TheContact />
  </div>
</template>

<script>

export default {
  name: 'Product',
  data() {
    return {
      slides: [
        {
          title: '产品中心',
          img: require('@/assets/product.png'),
        }
      ],
    }
  },
  methods: {
  }
}
</script>

<style scoped>

  .product-info {
    padding:20px 10%;
  }

  .product-info .cate-title {
    margin-bottom:10px;
    text-align:center;
  }

  .data-item {
    display: inline-block;
    margin:0px 2%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:21%;
    height:0;
    padding-top:14%;
    margin-top:60px;
    cursor: pointer;
    /* margin-right:12rpx; */
  }

  .data-item a {
    color: #525252;
  }

  .data-item a:hover{
    color: #009dff;
  }

 .data-item .item-img {
    display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
}

 .data-item .item-title {
    font-size:14px;
    text-align: center;
    position: absolute;
    top:-40px;
    width: 100%;
}

.item-right {
  display: block;
    width: 26px;
    height: 26px;
    border-radius: 60px;
    border: 2px #525252 solid;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    margin-top:20px;
}

.item-right:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 4px;
    margin: -4px 0 0 0;
    width: 10px;
    height: 10px;
    border-top: 2px solid #525252;
    border-right: 2px solid #525252;
    transform: rotate(45deg);
    transition: left 0.6s;
}

.item-right-hover {
  display: block;
    width: 26px;
    height: 26px;
    border-radius: 60px;
    border: 2px #009dff solid;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    margin-top:20px;
    background-color: #009dff;
}

.item-right-hover:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 4px;
    margin: -4px 0 0 0;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    transition: left 0.6s;
}

@media (max-width: 1360px) {
    .data-item {
      width:29.3%;
      padding-top:19.5%;
      /* margin-right:12rpx; */
    }
}

@media (max-width: 768px) {
    .data-item {
      width:46%;
      padding-top:30.7%;
      /* margin-right:12rpx; */
    }

    .product-info {
      padding:10px;
    }
}
</style>